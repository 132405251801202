export class MagicEdenSale
{

    constructor(signature, buyer, seller, mintAdress, price, blocktime, type)
    {
        this.signature = signature;
        this.buyer = buyer;
        this.seller = seller;
        this.mintAdress = mintAdress;
        this.price = price;
        this.blocktime = blocktime;
        this.type = type;
    }    

    getSignature()
    {
      return this.signature;
    }

    getMintAdress()
    {
      return this.mintAdress;
    }

    getType()
    {
      if(this.type === "buyNow")
        return "buy"
      else if(this.type === "sellNow")
        return "sell"

      return this.type;
    }

    getBuyer()
    {
      return this.buyer;
    }

    getSeller()
    {
      return this.seller;
    }

    getPrice()
    {
      return this.price;
    }

    getBlockTime()
    {
      return this.blocktime;
    }

    print(){
      console.log(this.signature);
      console.log(this.buyer);
      console.log(this.seller);
      console.log(this.mintAdress);
      console.log(this.price);
      console.log(this.blocktime);
      console.log(this.type);
    }
}