import useSWR from 'swr'
import {MagicEdenSale} from '../components/MagicEdenSale'
import SaleDisplay from '../components/SaleDisplay'

function Sales({w,s})
{
    const header = new Headers({ "Access-Control-Allow-Origin": "*" });
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    async function fetcher(...args)
    {
        console.log(...args);

        const res = await fetch(`${args}`, requestOptions);
        return res.json();
    }

    /*const fetcher = async (...args) => {
        const response = await fetch(args, {
          headers: {"Access-Control-Allow-Origin": "*"},
        }).then((res) => res.json());
        return response;
      };*/

    const urlPart1 = "https://api-mainnet.magiceden.dev/v2/wallets/";
    const urlPart2 = "/activities?offset=0&limit=100";

    const url = urlPart1 + w + urlPart2;
    const { data, error, isLoading } = useSWR(url, fetcher);

    var magicList = [];
    console.log(error);
    if (error) return <div>Failed to fetch users. </div>
    if (isLoading) return <h2>Loading...</h2>
    if (!data) return <h2>No data</h2>

    data.forEach((nb, i) => {
        if(data[i].signature === s && data[i].source === "magiceden_v2")
        {
            const m = new MagicEdenSale(data[i].signature, data[i].buyer, data[i].seller, data[i].tokenMint, data[i].price, data[i].blockTime, data[i].type);
            magicList.push(m);
        }
    })

    return (
      <SaleDisplay magicEden ={magicList}/>
      )
}

export default Sales;