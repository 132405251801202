import React from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import '../App.css';


function SaleDisplay({magicEden})
{

    return (
        <Grid container direction="column" spacing={{ xs: 2, md: 3 }} justifyContent="space-around" columns={{ xs: 4, sm: 8, md: 12 }} className="result">
          {magicEden.map((sale, index) => (
            <>
            <Grid item xs={2} sm={4} md={4} key={index} className="card">
            <CardContent className="card_content"> 
              <h1>{sale.getType()}</h1>
              <Typography sx={{ mb: 3.5 }} component="div">
                {sale.getSignature()}
              </Typography>
              <Typography sx={{ mb: 1.5 }} >
                Buyer Wallet: {sale.getBuyer()}
              </Typography>
              <Typography sx={{ mb: 1.5 }} >
                Seller Wallet: {sale.getSeller() ? sale.getSeller() : "-"}
              </Typography>
              <Typography >
                Price: {sale.getPrice()}
              </Typography>
              <Typography >
                Mint Address: {sale.getMintAdress()}
              </Typography>
              <Typography >
                Blocktime: {sale.getBlockTime()}
              </Typography>
            </CardContent>
          </Grid>
            </>
          ))}
      </Grid>
      )
}

export default SaleDisplay;