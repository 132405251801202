import './App.css';

import React, { useState } from 'react';
import Sales from './api/sales'
import Alert from '@mui/material/Alert';

/*Solana tools */
const solanaWeb3 = require('@solana/web3.js');
/*Endpoints dev */
//const endpointDev = 'https://api.devnet.solana.com';
/*Endpoints mainnet */
const endpoint = 'https://green-greatest-log.solana-mainnet.discover.quiknode.pro/47f9bb8d5f2581fef4561e4279827cbc1087877a/';
/*Endpoint connection */
const solanaConnection = new solanaWeb3.Connection(endpoint);

function App() {

  /*To save the wallet used for the transaction */
  const [wallet, setWallet] = useState("");
  /*The signature we want to know if made on MagicEden */
  const [signature, setSignature] = useState("");
  /*If there is an error */
  const [errorDisplay, setError] = useState("");

  /*Getting the wallet used for the transaction with the signature */
  const getTransaction = async(e) => {
  
    e.preventDefault();
    setSignature(e.target.name.value);
    try {
    let transactionSignature = await solanaConnection.getParsedTransaction(e.target.name.value, { maxSupportedTransactionVersion: 0 });
  
      if (transactionSignature == null){
        console.log("null");
      }
      else
      {
        let accountList = transactionSignature.transaction.message.accountKeys;
    
        console.log(`PublicKey: ${accountList[0].pubkey}`);
    
        setWallet(accountList[0].pubkey.toString());
        return accountList[0].pubkey.toString();
      }
    } catch (error) {
      setError(error)
      console.log(error);
    }
  }

  return (
    <div className='App'>
    <main className="main">
      <div className="description">
          <p>
            Wanna know if a Solana transaction has been made through MagicEden ?
          </p>
          <div>
          </div>
        </div>
          <div className="search">
            <form onSubmit={getTransaction}>
              <input
                id="name"
                name="name"
                type="text"
                required
              />
              <button
                type="submit"
              >
                Search
              </button>
            </form>
          </div>    
          
        <div className="center">
        {wallet ? (errorDisplay ? <Alert onClose={() => {setError("")}} severity="error">An error occured, please check that you used a valid signature !</Alert> : <Sales w={wallet} s={signature}/>) : null}
        </div>
    </main>
    </div>
  );
}

export default App;
